.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  background: rgba(9, 19, 33, 0.0638304);
  background-blend-mode: multiply;
  border-radius: 4px;
  color: #4f4f4f;
  width: 100%;
  padding: 8px 16px;
  margin-top: 8px;
}
.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 64px;
  width: 100%;
  background-color: #fff;
  /* Border / 03 */

  border: 1px solid #bababa;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
